import { PropsWithChildren, ReactElement } from 'react';
import styles from './index.module.scss';
import Breadcrumbs from './Breadcrumbs';
import { AppLinkDef } from '../../models/AppLinkDef';
import { Button } from '@getgo/chameleon-web-react-wrapper';
import { SettingsInactiveIcon } from '@getgo/chameleon-icons/react';
import { navigateToApp } from '../../../ui-frame/services/ui-frame.service';
import { useAppSelector } from '../../../core/store/root.store';
import { useTranslation } from 'react-i18next';

interface Props {
  title: ReactElement;
  breadcrumbLinks?: (AppLinkDef | null)[];
  actions?: ReactElement;
  badges?: ReactElement;
  alerts?: ReactElement;
}

function ContentHeader({ title, breadcrumbLinks, actions, badges, alerts }: PropsWithChildren<Props>): ReactElement {
  const showWUPBeta = useAppSelector(state => state.uiFrame.options.showWUPBeta);
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.wrapper}>
        <Breadcrumbs links={breadcrumbLinks ?? []} />
        {actions}
        {showWUPBeta && (
          <Button
            leadingIcon={<SettingsInactiveIcon />}
            variant="neutral"
            size="large"
            onClick={() => {
              navigateToApp('policies', '?policyType=windowsUpdate');
            }}>
            {t('updatesOverviewPage.managePolicyButton')}
          </Button>
        )}
      </div>
      <div className={styles.title}>
        {title}
        <div className={styles.badges}>{badges}</div>
      </div>
      {alerts && <div className={styles.alerts}>{alerts}</div>}
    </div>
  );
}

export default ContentHeader;
