import { InfoOutlinedIcon, PlayFilledIcon, ScreenOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Alert, Badge, Button, TabPanel, Tabs, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ChangeEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Actions from '../../../core/components/actions/Actions';
import ContentHeader from '../../../core/components/ContentHeader';
import { navigate, navigateToApp } from '../../../core/services/navigation.service';
import OnlineStatus from '../../models/OnlineStatus';
import styles from './DeviceDetails.module.scss';
import DeviceWindowsUpdatesGrid from './DeviceWindowsUpdatesGrid';
import DeviceSoftwareInventoryGrid from './DeviceSoftwareInventoryGrid/DeviceSoftwareInventoryGrid';
import useDisabledUpdateTooltipText from '../../hooks/useDisabledUpdateTooltipText';
import Tooltip from '../../../core/components/tooltips/Tooltip';
import InstallUpdatesState from './InstallUpdatesState';
import UpdateGridProps from './UpdateGridProps';
import useDevice from '../../hooks/useDevice';
import InProgressUpdate from '../../models/InProgressUpdate';
import useLocalStorage from 'use-local-storage';
import useCompanyUpdatesOverviewLink from '../CompanyUpdatesOverview/useCompanyUpdatesOverviewLink';
import OnlineStatusBadge from '../../../core/components/online-status/OnlineStatusBadge';
import { TrackedTab } from '../../../core/components/tracking/TrackedComponents';
import { DeviceDetailsTargetTab, DeviceDetailsViewedEvent } from '../../../core/models/UserTrackingEvents';
import { TabsComponent } from '@getgo/chameleon-web';
import GridFilterIds from '../../models/GridFilterIds';
import useCompanyDeviceUpdateStatus from '../../hooks/useCompanyDeviceUpdateStatus';
import DateTimeCellRenderer from '../../../core/components/grid/cell-renderers/DateTimeCellRenderer';
import { useAppSelector } from '../../../core/store/root.store';
import { selectNavigationRoute } from '../../../ui-frame/state/ui-frame.slice';

const osTabId = '/windows-updates';
const appTabId = '/application-updates';

const FILTER_FRAGMENTS = [GridFilterIds.ALL, GridFilterIds.VULNERABLE_DEVICES];
const inProgressUpdateTimeoutMs = 10 * 60 * 1000; // 10 min

function DeviceDetails(): ReactElement {
  const params = useParams();
  const deviceId = params.deviceId as string;

  const { error: updateStatusError, data: updateData } = useCompanyDeviceUpdateStatus();
  const deviceUpdateStatus = updateData?.find(device => device.device.id === deviceId);

  const updatesInProgressKey = 'updatesInProgress_' + deviceId;

  const { t } = useTranslation();
  const { data: device } = useDevice(deviceId);

  const [inProgressUpdates, setInProgressUpdates] = useLocalStorage<InProgressUpdate[]>(updatesInProgressKey, []);
  const [clearTimeoutFunctionsMap, setClearTimeoutFunctionsMap] = useState(new Map<string, () => void>());
  const clearTimeoutFunctionsMapRef = useRef(clearTimeoutFunctionsMap);
  const disabledUpdatesTooltipText = useDisabledUpdateTooltipText();
  const companyUpdatesOverviewLink = useCompanyUpdatesOverviewLink();

  const getClearTimeoutFunctionsMapKey = (id: string, version?: string): string =>
    version ? [id, version].join('_') : id;

  const addClearTimeoutFunction = useCallback((timeoutId: NodeJS.Timeout, id: string, version?: string) => {
    const updatedTimeoutMap = new Map(clearTimeoutFunctionsMapRef.current);
    updatedTimeoutMap.set(getClearTimeoutFunctionsMapKey(id, version), () => clearTimeout(timeoutId));
    setClearTimeoutFunctionsMap(updatedTimeoutMap);
  }, []);

  const removeClearTimeoutFunction = useCallback((id: string, version?: string) => {
    const updatedTimeoutMap = new Map(clearTimeoutFunctionsMapRef.current);
    updatedTimeoutMap.delete(getClearTimeoutFunctionsMapKey(id, version));
    setClearTimeoutFunctionsMap(updatedTimeoutMap);
  }, []);

  useEffect(() => {
    clearTimeoutFunctionsMapRef.current = clearTimeoutFunctionsMap;
  }, [clearTimeoutFunctionsMap]);

  const removeUpdateInProgress = useCallback(
    (id: string, version?: string) => {
      setInProgressUpdates(inProgressUpdates => {
        const newInProgressUpdates = [...(inProgressUpdates ?? [])];
        const index = newInProgressUpdates.findIndex(u => u.id === id && u.version === version);
        if (index >= 0) {
          newInProgressUpdates.splice(index, 1);
        }
        return newInProgressUpdates;
      });
      removeClearTimeoutFunction(id, version);
    },
    [removeClearTimeoutFunction, setInProgressUpdates]
  );

  const removeUpdateInProgressRef = useRef(removeUpdateInProgress);
  useEffect(() => {
    removeUpdateInProgressRef.current = removeUpdateInProgress;
  }, [removeUpdateInProgress]);

  useEffect(() => {
    // renew Timeouts on mount
    inProgressUpdates.forEach(update => {
      const msSinceStarted = Date.now() - update.startedAt;
      const timeoutId = setTimeout(
        () => removeUpdateInProgress(update.id, update.version),
        Math.max(0, inProgressUpdateTimeoutMs - msSinceStarted)
      );
      addClearTimeoutFunction(timeoutId, update.id, update.version);
    });
    // clear all untriggered timeout on unmount
    return (): void =>
      [...clearTimeoutFunctionsMapRef.current.values()].forEach(clearTimeoutFunction => clearTimeoutFunction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUpdatesInProgress = useCallback(
    (updates: { id: string; version?: string; scheduleDateTime?: string }[]) => {
      const newInProgressUpdates = [...inProgressUpdates];
      updates.forEach(update => {
        if (!inProgressUpdates.some(u => u.id === update.id && u.version === update.version)) {
          const startedAt = update.scheduleDateTime ? new Date(update.scheduleDateTime).getTime() : Date.now();
          newInProgressUpdates.push({
            id: update.id,
            ...(update.version ? { version: update.version } : {}),
            startedAt
          });
          const timeoutOffset = update.scheduleDateTime
            ? new Date(update.scheduleDateTime).getTime() - new Date().getTime()
            : 0;
          const timeoutId = setTimeout(
            () => removeUpdateInProgressRef.current(update.id, update.version),
            inProgressUpdateTimeoutMs + timeoutOffset
          );
          addClearTimeoutFunction(timeoutId, update.id, update.version);
        }
      });
      setInProgressUpdates(newInProgressUpdates);
    },
    [addClearTimeoutFunction, inProgressUpdates, setInProgressUpdates]
  );

  const [installUpdatesState, setInstallUpdatesState] = useState<InstallUpdatesState>({
    canUserInstall: false,
    handleInstallAllUpdatesClick: () => {
      console.log('this is old');
      return;
    },
    isInstallAllBtnDisabled: true,
    installRequestPending: false,
    buttonText: t('deviceDetails.actions.runAllOSUpdates')
  });

  const navigationRoute = useAppSelector(selectNavigationRoute);

  const activeTabId = ['/windows-updates', '/application-updates'].find(locationSuffix => {
    return navigationRoute.includes(locationSuffix);
  });

  let lastScanDate: Date | null | undefined = null;

  if (!updateStatusError) {
    lastScanDate =
      (activeTabId === '/windows-updates'
        ? deviceUpdateStatus?.lastScanOfWindowsUpdates
        : deviceUpdateStatus?.lastScanOfApplications) ?? deviceUpdateStatus?.lastScan;
  }

  const activeFilterId = FILTER_FRAGMENTS.find(id => {
    return navigationRoute.includes(id);
  });

  const [numberOfUpdatesInProgress, setNumberOfUpdatesInProgress] = useState(0);

  const onTabChange = ({ currentTarget }: ChangeEvent<TabsComponent>): void => {
    if (currentTarget.activetab.id === activeTabId) {
      return;
    }
    navigate({
      path: `/devices/:deviceId/updates/:activeTab${activeFilterId ?? GridFilterIds.ALL}` as any,
      params: { deviceId, activeTab: currentTarget.activetab.id }
    });
  };

  const onFilterChanged = (currentFilter: GridFilterIds): void => {
    navigate({
      path: `/devices/:deviceId/updates/:activeTab${currentFilter}` as '/devices/:deviceId/updates/:activeTab',
      params: { deviceId, activeTabId: activeTabId ?? osTabId }
    });
  };

  function isOnlineStatusEnabled(onlineStatus?: OnlineStatus): boolean {
    return onlineStatus !== OnlineStatus.Offline;
  }

  const sharedUpdateGridProperties: UpdateGridProps = {
    deviceId,
    device,
    inProgressUpdates,
    addUpdatesInProgress,
    isOnlineStatusEnabled,
    setInstallUpdatesState,
    setNumberOfUpdatesInProgress
  };

  return (
    <>
      <ContentHeader
        title={
          <Typography variant="heading-medium" tag="h1">
            {t('deviceDetails.title', { device: device?.name })}
          </Typography>
        }
        breadcrumbLinks={[companyUpdatesOverviewLink]}
        actions={
          <Actions>
            <Button
              variant="neutral"
              leadingIcon={<ScreenOutlinedIcon />}
              onClick={() => {
                navigateToApp({ path: '/devices/details/:deviceId', params: { deviceId } });
              }}>
              {t('deviceDetails.actions.manageDevice')}
            </Button>
            <Tooltip
              hidden={installUpdatesState.canUserInstall}
              trigger={
                <Button
                  leadingIcon={<PlayFilledIcon />}
                  onClick={installUpdatesState.handleInstallAllUpdatesClick}
                  disabled={installUpdatesState.isInstallAllBtnDisabled}
                  isLoading={installUpdatesState.installRequestPending}>
                  {installUpdatesState.buttonText}
                </Button>
              }>
              {disabledUpdatesTooltipText(device)}
            </Tooltip>
          </Actions>
        }
        badges={
          device && (
            <>
              <OnlineStatusBadge onlineStatus={device.onlineStatus} />
              <Badge variant="neutral" className={styles.lastScanBadge}>
                {lastScanDate && (
                  <>
                    {t('companyUpdatesOverview.grid.headers.lastScan')}:&nbsp;
                    <DateTimeCellRenderer value={lastScanDate}></DateTimeCellRenderer>
                  </>
                )}
              </Badge>
            </>
          )
        }
        alerts={
          <>
            {numberOfUpdatesInProgress > 0 && (
              <Alert icon={<InfoOutlinedIcon />}>{t('deviceDetails.alerts.updatesAreInProgress')}</Alert>
            )}
          </>
        }
      />
      <Tabs aria-label="tabs" className={styles.deviceDetailsTabs} onChange={onTabChange} activeid={activeTabId}>
        <TrackedTab id={osTabId} trackingEvent={new DeviceDetailsViewedEvent(DeviceDetailsTargetTab.WindowsUpdates)}>
          {t('deviceDetails.tabs.osUpdates')}
        </TrackedTab>
        <TrackedTab
          id={appTabId}
          trackingEvent={new DeviceDetailsViewedEvent(DeviceDetailsTargetTab.ApplicationUpdates)}>
          {t('deviceDetails.tabs.appUpdates')}
        </TrackedTab>
        <TabPanel className={[styles.deviceDetailsTabs, styles.tabPanel].join(' ')}>
          <DeviceWindowsUpdatesGrid
            isVisible={activeTabId === osTabId}
            initialFilter={GridFilterIds.ALL}
            onFilterChanged={onFilterChanged}
            {...sharedUpdateGridProperties}
          />
        </TabPanel>
        <TabPanel className={[styles.deviceDetailsTabs, styles.tabPanel].join(' ')}>
          <DeviceSoftwareInventoryGrid isVisible={activeTabId === appTabId} {...sharedUpdateGridProperties} />
        </TabPanel>
      </Tabs>
    </>
  );
}

export default DeviceDetails;
