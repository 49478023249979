import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '../../../../core/components/grid/Grid';
import InstalledSoftware from '../../../models/InstalledSoftware';
import { SelectionChangedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import UpdateGridProps from '../UpdateGridProps';
import useDeviceSoftwareInventory from '../../../hooks/useDeviceApplicationUpdates';
import { Health } from '../../../models/health';
import DeviceDetailsEmptyState from '../DeviceDetailsEmptyState';
import { SoftwareUpdateGridRow } from './SoftwareUpdateGridRow';
import useDeviceSoftwareInventoryColumnDefinitions from './useDeviceSoftwareInventoryColumnDefinitions';
import useUpdateInstallCapabilities from '../../../hooks/useUpdateInstallCapabilities';
import UpdateInstallGuard from '../../../hooks/UpdateInstallGuard';
import DeviceInventoryUpdateDrawer from '../../UpdateDrawer/DeviceInventoryUpdateDrawer';
import RefreshUpdateStatusButton from '../RefreshUpdateStatusButton';

export default function DeviceSoftwareInventoryGrid(
  props: {
    isVisible: boolean;
  } & UpdateGridProps
): ReactElement {
  const {
    isVisible,
    deviceId,
    device,
    inProgressUpdates,
    addUpdatesInProgress,
    isOnlineStatusEnabled,
    setInstallUpdatesState,
    setNumberOfUpdatesInProgress
  } = props;

  const {
    data: inventory,
    error,
    mutate: triggerSoftwareInventoryRefetch,
    isLoading: isLoadingSoftwareInventory
  } = useDeviceSoftwareInventory(deviceId);
  const { apps: softwareUpdates } = inventory ?? { device: undefined, apps: [] as InstalledSoftware[] };

  const { t } = useTranslation();
  const updateInstallCapabilities = useUpdateInstallCapabilities();
  const gridRef = useRef<AgGridReact | null>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [installRequestPending, setInstallRequestPending] = useState(false);
  const [selectedUpdates, setSelectedUpdates] = useState<SoftwareUpdateGridRow[]>([]);

  const loading = !inventory && !error;
  const healtStatus = error ? Health.Unknown : inventory?.errorCode ? Health.Error : Health.UpToDate;
  const updatesAvailable = softwareUpdates.length > 0 && !loading;
  const canUserInstall = updateInstallCapabilities.deviceCanInstallUpdates(props) && updatesAvailable;

  const resetGridProps = (): void => {
    setShowDrawer(false);
    setSelectedUpdates([]);
  };

  const isUpdateInProgress = (data: InstalledSoftware): boolean =>
    inProgressUpdates.some(update => update.id === data?.id && update.version === data?.availableVersions[0].version);

  const softwareUpdateData =
    softwareUpdates?.map(update => ({
      id: update.id,
      name: update.name,
      installedVersion: update.installedVersion,
      softwareUpdateStatus: {
        availableVersions: update.availableVersions,
        updateInProgress: isUpdateInProgress(update)
      },
      device
    })) ?? [];

  const numberOfUpdatesInProgress = softwareUpdateData.filter(
    update => update.softwareUpdateStatus.updateInProgress
  ).length;
  const isInstallAllBtnDisabled =
    !isOnlineStatusEnabled(device?.onlineStatus) ||
    !updatesAvailable ||
    softwareUpdates.length === numberOfUpdatesInProgress;

  const canUserInstallRef = useRef(canUserInstall);
  canUserInstallRef.current = canUserInstall;
  const isRowSelectableRef = useRef(
    (rowNode: { data?: SoftwareUpdateGridRow }): boolean =>
      canUserInstallRef.current && !rowNode.data?.softwareUpdateStatus.updateInProgress
  );

  const updateInstallGuard = UpdateInstallGuard.useForDevice();
  const handleInstallAllUpdatesClick = useCallback(() => {
    if (updateInstallGuard()) {
      return;
    }
    gridRef.current?.api.selectAll();
  }, [updateInstallGuard, gridRef]);

  const columnDefs = useDeviceSoftwareInventoryColumnDefinitions();

  useEffect(() => {
    if (isVisible) {
      setInstallUpdatesState({
        canUserInstall,
        handleInstallAllUpdatesClick,
        isInstallAllBtnDisabled,
        installRequestPending,
        buttonText: t('deviceDetails.actions.runAllAppUpdates')
      });
      setNumberOfUpdatesInProgress(numberOfUpdatesInProgress);
    } else {
      resetGridProps();
    }
  }, [
    isVisible,
    setInstallUpdatesState,
    canUserInstall,
    handleInstallAllUpdatesClick,
    isInstallAllBtnDisabled,
    installRequestPending,
    t,
    setNumberOfUpdatesInProgress,
    numberOfUpdatesInProgress
  ]);

  const deselectAll = useCallback((): void => gridRef.current?.api.deselectAll(), []);

  const handleSelectionChanged = useCallback((event: SelectionChangedEvent<SoftwareUpdateGridRow>) => {
    const rows = event.api.getSelectedRows();
    setSelectedUpdates(rows);
    rows.length ? setShowDrawer(true) : setShowDrawer(false);
  }, []);

  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      {!!inProgressUpdates.length && (
        <RefreshUpdateStatusButton
          triggerRevalidation={() => triggerSoftwareInventoryRefetch()}
          thereIsAnOngoingRequest={isLoadingSoftwareInventory}></RefreshUpdateStatusButton>
      )}

      {!loading && softwareUpdates.length == 0 && (
        <DeviceDetailsEmptyState
          health={healtStatus}
          isApplicationsUpdate={true}
          errorCode={inventory?.errorCode ?? null}
        />
      )}

      {(loading || softwareUpdates.length > 0) && (
        <Grid
          toolbarProps={{
            searchFieldPlaceholder: t('deviceDetails.grid.windows.toolbar.searchFieldPlaceholder')
          }}
          ref={gridRef}
          rowData={softwareUpdateData}
          columnDefs={columnDefs}
          onSelectionChanged={handleSelectionChanged}
          isRowSelectable={isRowSelectableRef.current}
          context={installRequestPending}
        />
      )}
      {showDrawer && (
        <DeviceInventoryUpdateDrawer
          onClose={deselectAll}
          selectedDeviceUpdates={
            device
              ? [
                  {
                    device: device,
                    updates: selectedUpdates.map(update => ({
                      type: 'winget-update',
                      appId: update.id,
                      appName: update.name,
                      installedVersion: update.installedVersion,
                      targetVersion: update.softwareUpdateStatus.availableVersions[0].version
                    }))
                  }
                ]
              : []
          }
          isInstallationPending={installRequestPending}
          setInstallationPending={setInstallRequestPending}
          addUpdatesInProgress={addUpdatesInProgress}
        />
      )}
    </>
  );
}
